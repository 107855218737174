
.contentPanel {

  background: white;
  padding: 1rem 0;
  position: relative;
  word-break: break-word;
  background-size: 100%;
  background-repeat: repeat;
  background-position: center;

  >* {
    z-index: 1;
    position: relative;
  }

  h2 a.button {
    font-size: 2.6rem;
  }

  h3 a.button {
    font-size: 2.2rem;
  }

  h4 a.button {
    font-size: 1.2rem;
  }

  img {
    max-width: 100%;
    height: auto;
  }

  &.commonFooter:before,
  &.bottomPanel:before {
    display: none;
  }

  a.button {
    color: $font-color;
    //background: $primary-color;
    background-color: transparent;
    padding: 0.2rem 1rem;
    font-weight: $weight-bold;
    border: 1px solid $font-color;
    border-radius: 10px;
    &:focus, &:hover {
      //background: $link-hover-color;
      color: white;
      background: #3B3B3B;
      text-decoration: none;
    }
  }

  a.p-button {
    border: 1px solid $font-color;
    background: transparent;
    padding: 0.2rem 1rem;
    font-weight: $weight-bold;
    padding-top: 0px;
    padding-bottom: 0px;
    line-height: 1;
    color: $font-color;
    border-radius: 10px;
    &:focus, &:hover {
      background: #3B3B3B;
      text-decoration: none;
    }
  }

}

.contentPanel--dark {
  background: linear-gradient(rgba(#000, 1), rgba(#000, 0.7));
  color: white;
}

.contentPanel__bg {
  position: absolute;
  overflow: hidden;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  .contentPanel--dark &:before {
    background: rgba(#fff, 0.05);
  }
}

//override bootstrap container size
.container{
  @include media-breakpoint-up(xl){
      max-width: 1140px;
  }
}