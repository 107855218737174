// .main-content {
//   padding-top: map-get($top-bar-height, mobile);

//   @include media-breakpoint-up(lg) {
//     padding-top: map-get($top-bar-height, desktop);
//   }
// }

.actions {
  margin: 1rem 0;
  text-align: center;
  border-radius: 0;
  button {
    margin-right: 0.7rem;
    border-radius: 10px;
	  background-color: transparent;
	  border-color: $font-color;
    color: $font-color;
    font-weight: $weight-bold;
	&:hover{
      background-color: #3B3B3B;
      border-color: #3B3B3B;
      color: white;
    }
  }
}

.actions.contact__form__button button{
  color: white;
}