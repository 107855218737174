.pageTiles {
  @include tile--titleDesc();
  margin-top: 2rem;
  margin-bottom: 2rem;
  margin-left: -0.7rem;
  margin-right: -0.7rem;

  .tile{
    //height: 100%;
    padding-left: 0.7rem;
    padding-right: 0.7rem;
  }

  &.useSlider {
    display: block;
    .tile {
      max-width: 100%;
      height: 100%;
    }
  }
}

.pageTile__content {

  &:hover,
  &:focus {
    text-decoration: none;
    // .pageTile_details:before {
    //   background: $primary-color;
    // }
    .pageTile_details {
      //background: $color-orange;
    }

    .pageTile__title,
    .pageTile_desc {
      color: white;
    }
    
    .tile__bg{
      transform: scale(1.05);
    }

  }
}

.pageTile__title {
  //font-family: $family-header;
  font-size: 1.1rem;
  text-transform: uppercase;
  font-weight: bold;
  line-height: 1;
  //margin: 0.5rem 0;
  color: white;
  @include media-breakpoint-down(xs){
    font-size: 1.3rem;
  }
  
}

.pageTile_desc {
  line-height: 1.3;
  font-size: 0.65rem;
  color: white;
  -webkit-line-clamp: 3;
  overflow: hidden;
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  //line-height: 1.1;
}

.pageTile__dot__style{
  //border-top-style: dashed;
  width: 40%;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  //padding-bottom: 0.5rem;
  border-top-color: white;
}

.pageTile_details_container{
  position: relative;
  overflow: hidden;
  flex-grow: 1;
  background-color: transparent;
  //margin-top: -20px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  //height: 197px;
  text-align: center;
  display: flex;
  flex-direction: column;
  //box-shadow: 3px 1px 12px rgba(0,0,0,0.7);
}

.pageTile_details {
  padding: 1rem 0.7rem;
  flex: 1 0 auto;
}

.contentPanel--dark {
  .pageTile_details:before {
    background-image: linear-gradient(to bottom left, rgba(#fff, 0.3), rgba(#fff, 0));
  }

  .pageTile__title,
  .pageTile_desc {
    color: white;
  }
}

.pageTile__bottom__img{
  width: 100%;
}

.pageTile__bottom__read__more{
  border: 1px solid white;
  border-radius: 6px;
  display: inline-block;
  margin: auto;
  font-size: 0.6rem;
  color: white;
  padding: 0.1rem 1.5rem;
}

.pageTile_details_container{
  // &::before{
  //   content: "";
  //   background: rgba(0,0,0,.3);
  //   width: 100%;
  //   height: 100%;
  //   position: absolute;
  //   z-index: 5;
  // }
}